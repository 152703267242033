 <div id="bgimg" class="bgimg">
<br>
   <div class="bgimg2" *ngIf="!isMobileResolution">
     <p align="justify"><span style="font-family: 'Open Sans', sans-serif; line-height: 150%; font-size: 18pt;">If you have found a tag with a code,
       you can enter it here and view information about your fish. After you have entered the code and
       clicked on "Show fish", you can click on the fish that is now displayed on the map and
       a popup will open with the relevant information.</span></p>
     <table cellpadding="5" cellspacing="0" style="width: 100%;border-color: #9a9a9a" border="0">
       <tbody>
       <tr>
         <td>
           <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
             <tbody>
             <tr>
               <td style="width: 20%" ></td>
               <td style="width: 20%; padding: 1em" > <mat-form-field>
                 <input matInput placeholder="insert code" aria-label="Code" [(ngModel)]="fisch" (ngModelChange)="changeFund()" [formControl]='senderControl' [matAutocomplete]="auto3" >
                 <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
                   <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                   <ng-container *ngIf="!isLoading">
                     <mat-option *ngFor="let code of filteredCode" [value]="code">
                       <span>{{ code.code }}</span>
                     </mat-option>
                   </ng-container>
                 </mat-autocomplete>
               </mat-form-field></td>
               <td style="width: 20%" ></td>
               <td style="width: 20%" ><!--<mat-checkbox color="primary" class="example-margin" [(ngModel)]="code" (ngModelChange)="changeFisch()">Fisch anzeigen</mat-checkbox>-->
               <button (click)="changeFisch()">show fish</button></td>
               <td style="width: 20%" ></td>
             </tr>
             </tbody>
           </table>

         </td>
       </tr>
       </tbody>
     </table>
   </div>

   <div class="bgimg2" *ngIf="isMobileResolution">
     <p align="justify"><span style="font-family: 'Open Sans', sans-serif; line-height: 150%; font-size: 12pt;">If you have found a tag with a code,
       you can enter it here and view information about your fish. After you have entered the code and
       clicked on "Show fish", you can click on the fish that is now displayed on the map and
       a popup will open with the relevant information.</span></p>
     <div id="table-mobile">
       <table cellpadding="5" style="width: 100%;border-color: #9a9a9a" border="0">
         <tbody>
         <tr>
           <td><br>
             <table cellpadding="5" style="width: 100%" border="0">
               <tbody>
               <table cellpadding="5" style="width: 100%" border="0">
                 <tbody>
                 <tr>
                   <td style="width: 10%;padding-right: 0.5em"></td>
                   <td style="width: 80%;padding-left: 0.5em; padding-top: 0.5em;margin: 0.5em"> <mat-form-field>
                     <input matInput placeholder="insert code" aria-label="Code" [(ngModel)]="fisch" [formControl]='senderControl' [matAutocomplete]="auto4" >
                     <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayFn">
                       <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                       <ng-container *ngIf="!isLoading">
                         <mat-option *ngFor="let code of filteredCode" [value]="code">
                           <span>{{ code.code }}</span>
                         </mat-option>
                       </ng-container>
                     </mat-autocomplete>
                   </mat-form-field></td>
                   <td style="width: 10%;padding-left: 0.5em"></td>
                 </tr>
                 </tbody></table><br>
               <tr>
                 <!--<td ><mat-checkbox color="primary" class="example-margin" [(ngModel)]="code" (ngModelChange)="changeFisch()">Fisch anzeigen</mat-checkbox></td>-->
                 <td ><button (click)="changeFisch()">show fish</button></td>
               </tr>
               </tbody>
             </table><br>

           </td>
         </tr>
         </tbody>
       </table>

       <br>
     </div></div>
   <!--<div  *ngIf="screenOrientation.type===screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY"><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br></div>
-->
   <div id="map-frame" class="map-frame">
        <div id="map"></div>
      </div>

    <br><br>

<!--

    <div class="bgimg3" *ngIf="!isMobileResolution">

   <br>

  <div style="padding: 5px;text-align: left;"><h2><strong>Details</strong></h2></div>
  <table cellpadding="5" cellspacing="0" style="width: 100%;border-color: #9a9a9a" border="3">
    <tbody>
    <tr>
      <td><br>
        <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
          <tbody>
          <tr>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
            <td style="padding-left: 0.5em;width: 14.285%" ><div [innerHTML]=details></div></td>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
            <td style="padding-left: 0.5em;width: 14.285%" ><div [innerHTML]=details2></div></td>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
            <td style="padding-left: 0.5em;width: 14.285%" ><div [innerHTML]=details3></div></td>
            <td style="padding-left: 0.5em;width: 14.285%" ></td>
          </tr><br>
          </tbody>
        </table><br>

      </td>
    </tr>
    </tbody>
  </table></div>

<div class="bgimg3" *ngIf="isMobileResolution">

  <div  *ngIf="screenOrientation.type===screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY"><br><br><br><br><br><br><br><br><br><br></div>

     <br>

     <div style="padding: 5px;text-align: left;"><h2><strong>Details</strong></h2></div>
     <table cellpadding="5" cellspacing="0" style="width: 100%;border-color: #9a9a9a" border="3">
       <tbody>
       <tr>
         <td><br>
           <table cellpadding="5" cellspacing="0" style="width: 100%" border="0">
             <tbody>
             <tr>
               <td style="padding: 0.5em" ><div [innerHTML]=details></div></td>

             </tr><br><tr>
               <td style="padding: 0.5em" ><div [innerHTML]=details2></div></td>

             </tr><br><tr>
               <td style="padding: 0.5em" ><div [innerHTML]=details3></div></td>

             </tr><br>
             </tbody>
           </table><br>

         </td>
       </tr>
       </tbody>
     </table></div>--><!--
   <table cellpadding="5" cellspacing="0" style="width: 100%" border="0"><tr>
           <td width="50%" style="text-align: right;"></td><td width="50%" style="text-align: left;">
           <div >
              &nbsp;<button mat-raised-button color="accent" size="large"><a href="" style="text-decoration:none" target="blank">
           <div style="font-size: 2em;color: white">PDF erstellen</div></a></button>
           </div></td></tr></table>-->
</div><br>
